<template>
  <div class="fixed max-w-container w-48 sm:w-64 mx-auto">
    <img
      :src="require('@/assets/images/support-button.png')"
      alt="support button"
      class="w-full cursor-pointer"
    >
  </div>
</template>

<script>
export default {
  name: 'SupportButton'
}
</script>
